import { passwordPolicyGuard } from '@logto/core-kit';
import { z } from 'zod';
export var LogResult;
(function (LogResult) {
    LogResult["Success"] = "Success";
    LogResult["Error"] = "Error";
})(LogResult || (LogResult = {}));
export const logContextPayloadGuard = z
    .object({
    key: z.string(),
    result: z.nativeEnum(LogResult),
    error: z.record(z.string(), z.unknown()).or(z.string()).optional(),
    ip: z.string().optional(),
    userAgent: z.string().optional(),
    userId: z.string().optional(),
    applicationId: z.string().optional(),
    sessionId: z.string().optional(),
})
    .catchall(z.unknown());
export const partialPasswordPolicyGuard = passwordPolicyGuard.deepPartial();
